import React from "react";
import styles from "../css/index.module.css";
/**
 * Here we create our Tabs that updates the setShowState of our media type
 */
const Tabs = ({ showState, setShowState }) => {
  return (
    <div className="row">
      {/* Showing State Text */}
      <div
        className={
          showState === "All"
            ? `col-4 ${styles.activeTab} ${styles.tab}`
            : `col-4 ${styles.tab}`
        }
        onClick={() => {
          setShowState("All");
        }}
      >
        All
      </div>

      {/* Showing State Images 
      <div
        className={
          showState === "Image"
            ? `col-4 ${styles.activeTab} ${styles.tab}`
            : `col-4 ${styles.tab}`
        }
        onClick={() => {
          setShowState("Image");
        }}
      >
        Image
      </div>*/}

      {/* Showing State Videos */}
      {/* <div
        className={
          showState === "Video"
            ? `col-4 ${styles.activeTab} ${styles.tab}`
            : `col-4 ${styles.tab}`
        }
        onClick={() => {
          setShowState("Video");
        }}
      >
        Video
      </div> */}
    </div>
  );
};

export default Tabs;
