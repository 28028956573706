import React, { useState } from "react";
import Select from "react-select";
import styles from "../css/index.module.css";

const marketOptions = [
  { value: "Brazil", label: "Brazil" },
  { value: "UK", label: "UK" },
  { value: "US", label: "US" },
  { value: "China", label: "China" },
];

const generationOptions = [
  { value: "GenZ", label: "GenZ" },
  { value: "Millennial", label: "Millennial" },
  { value: "GenX", label: "GenX" },
  { value: "Boomer", label: "Boomer" },
];

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

const segmentOptions = [
  { value: "Advocate", label: "Advocate" },
  { value: "Loyalist", label: "Loyalist" },
  { value: "Indulger", label: "Indulger" },
  { value: "Nurturer", label: "Nurturer" },
  { value: "Guardian", label: "Guardian" },
  { value: "Creator", label: "Creator" },
];

const Filters = ({
  setCountry,
  country,
  setGeneration,
  generation,
  gender,
  setGender,
  segment,
  setSegment,
}) => {
  const [extended, setExtended] = useState(false);

  return (
    <React.Fragment>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => setExtended(!extended)}
      >
        Filter
      </button>
      <ul
        className={`dropdown-menu ${styles.dropdownMenu} ${
          extended ? styles.shown : ""
        }`}
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <Select
            defaultValue={country}
            isMulti
            name="colors"
            options={marketOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setCountry(value);
              console.log(value);
            }}
            closeMenuOnSelect={false}
            placeholder="Market"
          />
        </li>
        <li>
          <Select
            defaultValue={generation}
            isMulti
            name="colors"
            options={generationOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setGeneration(value);
              console.log(value);
            }}
            closeMenuOnSelect={false}
            placeholder="Generation"
          />
        </li>
        <li>
          <Select
            defaultValue={gender}
            isMulti
            name="colors"
            options={genderOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setGender(value);
              console.log(value);
            }}
            closeMenuOnSelect={false}
            placeholder="Gender"
          />
        </li>
        <li>
          <Select
            defaultValue={segment}
            isMulti
            name="colors"
            options={segmentOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setSegment(value);
              console.log(value);
            }}
            closeMenuOnSelect={false}
            placeholder="Segment"
          />
        </li>
      </ul>
    </React.Fragment>
  );
};
// drop down multiselect, use a join
export default Filters;
