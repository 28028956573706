import React from "react";
import styles from "../css/index.module.css";

const TabsResults = ({ allData, allSearch, searchStatus, showState }) => {
  return (
    <div>
      {/* All Dta */}
      <div className={styles.result}>
        <div
          className={
            showState === "Text"
              ? `${styles.active} ${styles.contentTab} ${styles.textTab}`
              : `${styles.contentTab} ${styles.textTab}`
          }
        >
          {allSearch.length !== 0 ? allData() : searchStatus}
        </div>
      </div>
    </div>
  );
};

export default TabsResults;
