import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../css/index.module.css";
import Search from "./Search";
import Filters from "./Filters";
import Tabs from "./Tabs";
import TabsResults from "./TabsResults";
import logo from "../assets/images/logo.png";

function Home() {
  // useState always at the top of your file and grouped into sections:

  // Setting tab type
  const [allSearch, setAll] = useState([]);

  // Filters
  const [generation, setGeneration] = useState([]);
  const [country, setCountry] = useState([]);
  const [segment, setSegment] = useState([]);
  const [gender, setGender] = useState([]);

  // Find out what difference between query and searchStatus is
  const [query, setQuery] = useState(""); // Query is the input
  const [searchStatus, setStatus] = useState("Enter Search Query"); // MediaDisplay status/ gives user idea of what they have to do
  const [showState, setShowState] = useState("All"); // Type of media shown on page/ what tab it should be on by default
  const [queryTitle, setQueryTitle] = useState(""); // Logged query to allow use to view what they have searched.
  // anti pattern
  // Runs once to confirm page is loaded
  useEffect(() => {
    console.log("page loaded");
  }, []);

  // Bootstrap loaded method
  const Loading = (
    <div className={styles.lds_roller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  // Method that fetches the data

  const fetchApi = async (query, props) => {
    // all
    const allResult = `https://europe-west2-content-work.cloudfunctions.net/infocus_api/?asset=all&Segment=${props.segment
      .reduce(
        (accumulator, currentValue) => currentValue.value + "," + accumulator,
        ""
      )
      .slice(0, -1)}&Generation=${props.generation
      .reduce(
        (accumulator, currentValue) => currentValue.value + "," + accumulator,
        ""
      )
      .slice(0, -1)}&Market=${props.country
      .reduce(
        (accumulator, currentValue) => currentValue.value + "," + accumulator,
        ""
      )
      .slice(0, -1)}&Gender=${props.gender
      .reduce(
        (accumulator, currentValue) => currentValue.value + "," + accumulator,
        ""
      )
      .slice(0, -1)}`;
    const encodedAllSearch = encodeURI(allResult);
    console.log('encoded all search: ', encodedAllSearch);

    try {
      const data = await axios.all([await axios.get(encodedAllSearch)]);
      console.log('AXIOS DATA', data);
      return data;
    } catch (error) {
      console.log(error);
      return []; // Will stop our then from breaking, then our then is
    }
  };

  // Search if empty. Then add to resolution query so that we can view what we are looking for
  const search = (e) => {
    // if (query === undefined || query === "") {
    //   alert("Enter Proper Search Query");
    //   return;
    // }
    var resQ = query;
    if (
      generation.length !== 0 ||
      gender.length !== 0 ||
      segment.length !== 0 ||
      country.length !== 0
    ) {
      resQ += "";
      if (country.length !== 0) {
        resQ +=
          "Country: " +
          country
            .reduce(
              (accumulator, currentValue) =>
                currentValue.value + ", " + accumulator,
              ""
            )
            .slice(0, -2) +
          "  ";
      }
      if (generation.length !== 0) {
        resQ +=
          "Generation: " +
          generation
            .reduce(
              (accumulator, currentValue) =>
                currentValue.value + ", " + accumulator,
              ""
            )
            .slice(0, -2) +
          "  ";
      }
      if (gender.length !== 0) {
        resQ +=
          "Gender: " +
          gender
            .reduce(
              (accumulator, currentValue) =>
                currentValue.value + ", " + accumulator,
              ""
            )
            .slice(0, -2) +
          "  "
      }
      if (segment.length !== 0) {
        resQ +=
          "Segment : " +
          segment
            .reduce(
              (accumulator, currentValue) =>
                currentValue.value + ", " + accumulator,
              ""
            )
            .slice(0, -2) +
          "  ";
      }
    }

    // Add searchResult is the css
    // Add the text to this Search..
    setQueryTitle(resQ);
    // Reset to empty after query has been triggered and search resQ displays on page
    setAll([]);
    setSegment("");
    setGender("");
    setCountry("");
    setGeneration("");
    setStatus(Loading); // Passing a componenet here

    // Prevent default - stops the native event behaviour from happening e.g. click event. you will always see this
    // not sure why we print out query here
    e.preventDefault();
    console.log(query);

    // An object of the params was created to make code more scalable i.e. if more filters were added we could generate here. Also adheres to type safety and error handling
    const params = {
      generation: generation,
      country: country,
      segment: segment,
      gender: gender,
    };
    // Fetch Api method is called here.
    // Sets data to its types
    // fetching everything all at same time.
    fetchApi(query, params).then((data) => {
      if (data[0]) {
        console.log("data");
        console.log(data[0].data);
        setAll(data[0].data);
      }
    });
    // Sets search bar to empty
    setQuery("");
  };

  // Loops through text data
  const allData = () => {
    let data = allSearch.hits;
    console.log("all data");
    console.log(data.length);

    if (!data || data.length === 0) {
      return <p className={styles.noData}>No Data Found</p>;
    } else {
      // remove duplicate entries
      data = [ ...new Set(data) ];
    }

    return data.map((item, index) => {
      // console.log(item.Question);
      if (item.path.includes('.mov') || item.path.includes('.mp4') || 
          item.path.includes('.MP4')  || item.path.includes('.MOV') || item.path.includes('.zip')) {

      } else {
      return (
       <div>
        <a class='lightbox' href={"#" + index} key={index} >
          <img
            src={item.path}
            alt={item.Name}
            key={index}
            className={styles.imgs}
          />
        </a>
       <div class="lightbox-target" id={index}>
          <img src={item.path}/>
          <a class="lightbox-close" href="#"></a>
          <div class='lightbox-question'>Question: {item.Question}</div>
          <div class='lightbox-answer'>Answer: {item.Answer}</div>
          <div class='lightbox-title'>{item.Title}</div>
          <div class='lightbox-tag'>{item.tag}</div>
        </div>
       </div>
      );
      }

    });
  };

  //          <h4>{item.Question}</h4>
          // Answer: {item.Answer}
          // {item.Title}
          // {item.Tag}

  return (
    <div className={styles.search}>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
        <img src={logo} alt="Inpire-Logo" height="100px" />
        <button
          type="submit"
          className="btn btn-primary w-20"
          onClick={() => window.location.reload(false)}
        >
          Reload Page
        </button>
      </nav>

      <br></br>
      {/* <h1 className="text-center p-3">Inspire Infocus</h1> */}

      {/* Search bar */}
      <div className="row">
        <div className="col-lg-10 col-sm-9">
          <Search
            query={query}
            setQuery={(q) => setQuery(q)}
            search={(e) => search(e)}
          />
        </div>

        {/* Drop down for filter */}
        <div className={`col-sm-3 col-lg-2 ${styles.filter}`}>
          <div className={`dropdown ${styles.dropdown}`}>
            <Filters
              setCountry={setCountry}
              country={country}
              setGeneration={setGeneration}
              generation={generation}
              gender={gender}
              setGender={setGender}
              segment={segment}
              setSegment={setSegment}
            />
          </div>
        </div>
      </div>

      {/* Showing State Text */}
      <div className="mt-4">
        <div id="searchResult" className={styles.searchResult}>
          {queryTitle}
        </div>

        <div className={styles.content} className="container">
          <Tabs showState={showState} setShowState={(s) => setShowState(s)} />
          <TabsResults
            allData={(t) => allData(t)}
            allSearch={allSearch}
            searchStatus={searchStatus}
            showState={showState}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
