import React from "react";
/**
 * Here we create our search input and button
 */
const Search = ({ query, setQuery, search }) => {
  return (
    <form className="row">
      <div className="col-10 col-md-11">
        {/* <input
          type="search"
          className="form-control"
          id="search"
          placeholder="Enter Query To Search"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        /> */}
      </div>

      {/* Submit Button */}
      <div className="col-2 col-md-1">
        <button
          type="submit"
          className="btn btn-primary w-100"
          // onClick will automatically give you the "e" as the default argument
          onClick={search}
        >
          🔎
        </button>
      </div>
    </form>
  );
};

export default Search;
